import React from 'react';
import PropTypes from 'prop-types';

const BlockText = ({ title, content, className }) => {
  return (
    <section className={`flex justify-center items-center ${className}`}>
      <div className="relative max-w-5xl my-8 bg-white text-black rounded-lg shadow-lg overflow-hidden">
        <div className="absolute left-0 top-0 bottom-0 w-10 bg-indigo-300">
          <div className="w-full h-full bg-white/30 transform rotate-45" aria-hidden="true" />
        </div>
        <div className="p-8 ml-10">
          <h2 className="text-2xl font-bold mb-6">{title}</h2>
          {content.map((paragraph, index) => (
            <p key={index} className="mb-4 last:mb-0">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

BlockText.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

BlockText.defaultProps = {
  className: '',
};

export default BlockText;