import image1 from '../images/image.png';
import image2 from '../images/img2.webp';
import image3 from '../images/img3.webp';
import image4 from '../images/img4.webp';
import image5 from '../images/img5.webp';

const rugsData = [
            {
                images: [image2],
                title: 'Tapis Florale - Beige (Réf: 042)',
                designer: '140cm x 260cm',
                originalPrice: '120,00',
                // discount: '20%',
                finalPrice: '120,20',
                available: false, // Add this line

            },
            {
                images: [image3],
                title: 'Tapis Azzurra - Bleu (Réf: 046)',
                designer: '140cm x 260cm',
                originalPrice: '120,00',
                // discount: '20%',
                finalPrice: '120,20',
                available: false, // Add this line

            },
            {
                images: [image4],
                title: 'Tapis Roma - Rouge (Réf: 050)',
                designer: '140cm x 260cm',
                originalPrice: '120,00',
                // discount: '20%',
                finalPrice: '120,20',
                available: false, // Add this line

            },
            {
                images: [image5],
                title: 'Tapis Palermo - Beige (Réf: 054)',
                designer: '140cm x 260cm',
                originalPrice: '120,00',
                // discount: '20%',
                finalPrice: '120,20',
                available: false, // Add this line

            },
            ];

export default rugsData;