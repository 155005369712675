import React from 'react';
import { Link } from 'react-router-dom';
import blogData from '../../data/blogData';

const BlogPage = () => {
  return (
    <div className="bg-gradient-to-r from-purple-20  to-indigo-100 min-h-screen pt-20">
      <div className="container mx-auto px-4 py-8">

        <h1 className="text-5xl font-extrabold text-center text-gray-800 mb-12 tracking-tight">
          <span className="text-indigo-500 font-serif">Blog</span>
        </h1>
      <div className="border-t-2 border-gray-300 mx-auto w-80 my-4 pt-10">

        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {blogData.map((article) => (
            <div key={article.id} className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-2">
              <img 
                src={article.image || 'https://via.placeholder.com/400x250'} 
                alt={article.title} 
                className="w-full h-56 object-cover"
              />
              <div className="p-6 flex flex-col h-64">
                <h2 className="text-2xl font-bold text-gray-800 mb-3 line-clamp-2">
                  <Link to={`/article/${article.id}`} className="hover:text-indigo-500 transition-colors duration-300">
                    {article.title}
                  </Link>
                </h2>
                <p className="text-gray-600 mb-4 flex-grow line-clamp-3 text-justify">{article.content}</p>
                <div className="flex justify-between items-center mt-auto">
                  <Link 
                    to={`/article/${article.id}`} 
                    className="inline-block bg-indigo-500 text-white px-4 py-2 rounded-md font-semibold hover:bg-indigo-300 transition-colors duration-300"
                  >
                    Lire l'article
                  </Link>
                  <span className="text-sm text-gray-500">{article.date}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;