import React, { useState } from 'react';
import { FaWhatsapp, FaPhoneAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Rug = ({ images, title, designer, originalPrice, discount, finalPrice, available }) => {
  const [showModal, setShowModal] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = () => setShowModal(!showModal);
  const handleContactClick = () => setShowContactInfo(!showContactInfo);

  const handlePrevClick = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleWhatsappClick = () => {
    const message = `Hello, I want to buy this product "${title}". Is it available?`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <div className={`w-full max-w-sm mx-auto bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 ease-in-out hover:shadow-xl sm:max-w-md md:max-w-lg lg:max-w-xl ${!available ? 'opacity-50' : ''}`}>
        <div className="relative cursor-pointer group" onClick={handleImageClick}>
          {discount && available && (
            <div className="absolute top-3 left-3 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded-full z-10">- {discount}</div>
          )}
          {!available && (
            <div className="absolute top-3 left-3 bg-gray-900 text-white text-xs font-bold px-2 py-1 rounded-full z-10">Stock épuisé</div>
          )}
          <img
            src={images[currentIndex]}
            alt={title}
            className="w-full h-48 sm:h-56 md:h-64 lg:h-72 object-contain transition-transform duration-300 group-hover:scale-105"
          />
          {images.length > 1 && (
            <>
              <button onClick={handlePrevClick} className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 text-gray-800 rounded-full p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <FaChevronLeft />
              </button>
              <button onClick={handleNextClick} className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 text-gray-800 rounded-full p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <FaChevronRight />
              </button>
            </>
          )}
        </div>
        <div className="p-4 sm:p-5 flex flex-col">
          <div className="flex-grow">
            <h3 className="text-base sm:text-lg font-semibold text-gray-800 mb-1">{title}</h3>
            <p className="text-xs sm:text-sm text-gray-600">Taille : {designer}</p>
          </div>
          <div className="flex flex-wrap items-center justify-between mt-3">
            <div className="flex flex-col mb-2 sm:mb-0">
              <span className="text-xl sm:text-2xl font-bold text-gray-800">{finalPrice} €</span>
              {discount && available && (
                <span className="text-xs sm:text-sm text-gray-400 line-through">{originalPrice} €</span>
              )}
            </div>
            <button
              onClick={handleContactClick}
              className={`w-full sm:w-auto bg-slate-600 hover:bg-slate-500 text-white font-bold py-2 px-3 sm:px-4 rounded-full transition-colors duration-300 text-sm sm:text-base ${!available ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={!available}
            >
              {available ? 'Contacter' : 'Stock épuisé'}
            </button>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4" onClick={handleImageClick}>
          <div className="relative bg-white p-4 rounded-lg max-w-4xl max-h-[90vh] w-full overflow-auto" onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white text-gray-800 rounded-full p-2 sm:p-3 shadow-md hover:bg-gray-100 transition-colors duration-300"
              onClick={handlePrevClick}
            >
              <FaChevronLeft className="text-lg sm:text-xl" />
            </button>
            <img src={images[currentIndex]} alt={title} className="max-w-full max-h-[80vh] object-contain mx-auto" />
            <button
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white text-gray-800 rounded-full p-2 sm:p-3 shadow-md hover:bg-gray-100 transition-colors duration-300"
              onClick={handleNextClick}
            >
              <FaChevronRight className="text-lg sm:text-xl" />
            </button>
            <button
              className="absolute top-2 right-2 sm:top-4 sm:right-4 text-3xl sm:text-4xl text-black hover:text-gray-300 transition-colors duration-300"
              onClick={handleImageClick}
            >
              ×
            </button>
          </div>
        </div>
      )}

      {showContactInfo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4" onClick={handleContactClick}>
          <div className="relative bg-white py-6 sm:py-8 px-6 sm:px-10 rounded-lg w-full max-w-md mx-auto overflow-hidden shadow-xl" onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-2 right-2 sm:top-4 sm:right-4 text-2xl sm:text-3xl text-gray-700 hover:text-gray-900 transition-colors duration-300"
              onClick={handleContactClick}
            >
              ×
            </button>
            <div className="text-center">
              <FaPhoneAlt className="mx-auto mb-4 text-blue-500 text-3xl sm:text-4xl" />
              <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">Nous contacter</h2>
              <div className="flex items-center justify-center space-x-2 bg-gray-100 p-3 sm:p-4 rounded-lg mb-6">
                <FaPhoneAlt className="text-blue-500" />
                <span className="text-base sm:text-lg font-semibold text-gray-700">07 82 80 80 80</span>
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center space-y-3 sm:space-y-0 sm:space-x-4">
                <a
                  href="tel:0782808080"
                  className="w-full sm:w-auto bg-indigo-400 hover:bg-indigo-600 text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-lg transition-colors duration-300 text-sm sm:text-base"
                >
                  Appeler
                </a>
                <button
                  onClick={handleWhatsappClick}
                  className="w-full sm:w-auto flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-lg transition-colors duration-300 text-sm sm:text-base"
                >
                  <FaWhatsapp className="mr-2 text-xl sm:text-2xl" /> WhatsApp
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Rug;