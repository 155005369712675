import React, { useState, useCallback, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import image1 from '../../images/banner3.svg';

const images = [
  { src: image1, alt: 'Description of image 1' },
  // { src: image2, alt: 'Description of image 2' },
  // { src: image3, alt: 'Description of image 3' },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }, []);

  const goToPrev = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  }, []);

  useEffect(() => {
    let interval;
    if (isAutoPlaying) {
      interval = setInterval(goToNext, 5000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlaying, goToNext]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') goToPrev();
      if (event.key === 'ArrowRight') goToNext();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [goToPrev, goToNext]);

  const handleMouseEnter = () => setIsAutoPlaying(false);
  const handleMouseLeave = () => setIsAutoPlaying(true);

  return (
    <div 
      className="relative w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] overflow-hidden rounded-lg shadow-lg"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Navigation Arrows */}
      <button
        className="absolute top-1/2 left-2 sm:left-6 transform -translate-y-1/2 z-10 text-white bg-black bg-opacity-50 p-1 sm:p-2 rounded-full hover:bg-opacity-75 transition duration-1000"
        onClick={goToPrev}
        aria-label="Previous image"
      >
        <ChevronLeftIcon size={16} className="sm:w-6 sm:h-6" />
      </button>
      <button
        className="absolute top-1/2 right-2 sm:right-6 transform -translate-y-1/2 z-10 text-white bg-black bg-opacity-50 p-1 sm:p-2 rounded-full hover:bg-opacity-75 transition duration-1000"
        onClick={goToNext}
        aria-label="Next image"
      >
        <ChevronRightIcon size={16} className="sm:w-6 sm:h-6" />
      </button>

      {/* Slides */}
      <div
        className="flex h-full transition duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div key={index} className="shrink-0 w-full h-full">
            <img
              src={image.src}
              alt={image.alt}
              className="object-cover object-center w-full h-full"
              loading={index === 0 ? "eager" : "lazy"}
            />
          </div>
        ))}
      </div>

      {/* Indicators */}
      <div className="absolute bottom-2 sm:bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-2 sm:space-x-3 z-10">
        {images.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 sm:w-3 sm:h-3 rounded-full transition duration-1000 ${
              index === currentIndex ? 'bg-white' : 'bg-white bg-opacity-50 hover:bg-opacity-75'
            }`}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Go to image ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;