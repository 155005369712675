import image5 from '../images/img5.webp';
import image2 from '../images/img2.webp';

const blogData = [
  {
    id: 1,
    title: 'Comment nettoyer son tapis',
    content: "Le nettoyage régulier de votre tapis est essentiel pour maintenir son apparence et sa durabilité. Commencez par passer l'aspirateur soigneusement sur toute la surface, en insistant sur les zones à fort trafic. Utilisez les différents embouts de votre aspirateur pour atteindre les coins et les bords. Pour un nettoyage en profondeur, saupoudrez du bicarbonate de soude sur le tapis la veille au soir et laissez agir toute la nuit avant d'aspirer. Cela permettra d'éliminer les odeurs tenaces. \nPour les taches fraîches, agissez rapidement ! Épongez délicatement avec un chiffon propre sans frotter, pour éviter d'étaler la tache. Ensuite, appliquez une solution de nettoyage adaptée au type de tache et de fibre de votre tapis. Pour les taches plus anciennes, vous pouvez utiliser un mélange d'eau tiède et de vinaigre blanc, ou un nettoyant spécial tapis disponible dans le commerce. Testez toujours la solution sur une petite zone peu visible avant de l'appliquer sur l'ensemble de la tache. \nPour un nettoyage en profondeur, envisagez de louer une machine à shampouiner les tapis ou faites appel à un professionnel une fois par an. Ces méthodes permettent d'éliminer la saleté incrustée et de raviver les couleurs de votre tapis. N'oubliez pas de bien laisser sécher votre tapis après un nettoyage en profondeur pour éviter la formation de moisissures. Enfin, pour préserver votre tapis entre les nettoyages, pensez à le tourner régulièrement pour répartir l'usure et placez des tapis d'entrée pour limiter l'apport de saleté.",
    image: image5, // Replace with your image URL
  },
  // {
  //   id: 2,
  //   title: 'Article 2',
  //   content: 'This is the content of Article 2.',
  //   image: image2, // Replace with your image URL
  // },
  // // Add more articles as needed
];

export default blogData;