import React from 'react';
import { useParams, Link } from 'react-router-dom';
import blogData from '../../data/blogData';

const ArrowLeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
  </svg>
);

const ArticlePage = () => {
  const { id } = useParams();
  const article = blogData.find(article => article.id === parseInt(id, 10));

  if (!article) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-50 to-indigo-100 pt-20">
        <div className="text-center">
          <h1 className="text-6xl font-serif font-extrabold text-gray-900 mb-6 animate-fade-in-down">Article Not Found</h1>
          <Link to="/blog" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 transition-transform duration-300 scale-105 shadow-lg">
            Return to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-purple-50 to-indigo-100 min-h-screen pt-20">
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <Link to="/blog" className="inline-flex items-center text-indigo-600 hover:text-indigo-800 transition-colors duration-300 mb-8 group">
          <ArrowLeftIcon className="group-hover:-translate-x-1 transition-transform duration-300" />
          <span className="font-medium">Retour au Blog</span>
        </Link>
        
        <article className="bg-white rounded-2xl shadow-2xl overflow-hidden transition-shadow duration-300 hover:shadow-3xl p-8 md:p-12">
        <h1 className="text-center text-4xl md:text-5xl lg:text-6xl font-serif text-gray-900 mb-8 leading-tight tracking-tight animate-fade-in-down">
            {article.title}
          </h1>
          {/* Add the image */}
          <img
            src={article.image || 'https://via.placeholder.com/800x400'}
            alt={article.title}
            className="w-full h-64 object-cover mb-8"
          />

          <div className="prose prose-lg max-w-none text-gray-700">
            {article.content.split('\n').map((paragraph, index) => (
              <p key={index} className="mb-6 animate-fade-in">{paragraph}</p>
            ))}
          </div>
        </article>
      </div>
    </div>
  );
};

export default ArticlePage;