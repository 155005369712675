// RugGallery.js
import React, { useState } from 'react';
import Rug from '../Rug/Rug';
import rugsData from '../../data/rugsData';

const RugGallery = () => {
  const [currentImage, setCurrentImage] = useState(null);

  const handleImageClick = (image) => {
    setCurrentImage(image);
  };

  const handleCloseModal = () => {
    setCurrentImage(null);
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="text-center">
        <h2 className="text-3xl font-serif text-center text-gray-800 mb-2">Notre collection</h2>
        <div className="border-t-2 border-gray-300 mx-auto w-80 my-4"></div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {rugsData.map((rug) => (
          <Rug
            key={rug.title}
            {...rug}
            onImageClick={handleImageClick}
            className={`${rug.available ? '' : 'grayscale'}`} // Apply grayscale if not available
          />
        ))}
      </div>

      {currentImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg overflow-hidden max-w-4xl max-h-[90vh] relative">
            <button onClick={handleCloseModal} className="absolute top-4 right-4 text-gray-600 hover:text-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <img src={currentImage} alt="Enlarged" className="w-full h-auto object-contain" />
          </div>
        </div>
      )}
    </div>
  );
};

export default RugGallery;