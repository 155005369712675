import React from 'react';
import RugGallery from './components/RugGallery/RugGallery';
import Navbar from './components/Navbar/Navbar';
import Carrousel from './components/Carrousel/Carrousel';
import Footer from './components/Footer/Footer';
import BlockText from './components/BlockText/BlockText';
import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogPage from './components/BlogPage/BlogPage';
import ArticlePage from './components/ArticlePage/ArticlePage';
import { useEffect } from 'react';
import AboutPage from './components/AboutPage/AboutPage';

function App() {
  useEffect(() => {
    // Load Tally embed script
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div className="App flex flex-col min-h-screen bg-purple-50">
        <Navbar />
        <main className="flex-grow pt-16">
          <Routes>
            <Route
              path="/"
              element={
                <div className="Carrousel">
                  <Carrousel />
                  <BlockText
                    title="Comment acheter ?"
                    content={[
                      "Le site sera lancé le 23 septembre. Inscrivez-vous pour recevoir un mail lors du lancement.",
                      "Découvrez notre vaste catalogue de tapis et trouvez celui qui sublimera votre intérieur. Une fois votre choix fait, contactez-nous rapidement : nos stocks sont limités ! Le paiement s'effectue sur place au moment de la livraison (Toulouse et environs).",
                      "Vous n'habitez pas Toulouse ? Pas de problème ! Laissez-nous votre numéro de téléphone, et nous vous contacterons lors de notre prochain passage dans votre ville.",
                      "Pour rester informé de nos dernières nouveautés et offres exclusives, inscrivez-vous à notre newsletter en bas de page. Ne manquez aucune opportunité de décorer votre intérieur avec style !",
                      "N'hésitez pas à utiliser notre formulaire de contact en bas de page pour toute question ou demande spécifique. Notre équipe se fera un plaisir de vous répondre dans les plus brefs délais."
                    ]}
                  />
                  <div className="rug-gallery-wrapper max-w-full md:max-w-[60%] mx-auto text-center">
                    <RugGallery />
                  </div>
                </div>
              }
            />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/article/:id" element={<ArticlePage />} />
            <Route path="/propos" element={<AboutPage />} /> {/* Add this line */}

          </Routes>
        </main>
        <div className="bg-white rounded-lg shadow-lg mx-auto w-11/12 max-w-5xl my-12 p-8">
  <h2 className="text-4xl font-serif text-center text-gray-800 mb-6">Contactez-nous 😀</h2>
  <div className="border-t-2 border-gray-200 mx-auto my-6 pt-8">
    <iframe 
      data-tally-src="https://tally.so/embed/wv46G0?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
      loading="lazy" 
      width="100%" 
      height="500" 
      frameBorder="0" 
      marginHeight="0" 
      marginWidth="0" 
      title="Gardons contact 😀"
      className="transition-all duration-300 hover:shadow-xl"
    ></iframe>
  </div>
</div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;