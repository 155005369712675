import React from 'react';
import PropTypes from 'prop-types';
import proposData from '../../data/proposData';

const Section = ({ title, content, imageSrc, imageAlt, isReversed }) => (
  <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} gap-12 items-center`}>
    <div className="w-full md:w-1/2 flex justify-center">
      <div className="w-100 h-100 overflow-hidden rounded-2xl shadow-2xl transform transition-all duration-300 hover:scale-105">
        <img 
          src={imageSrc} 
          alt={imageAlt} 
          className="w-full h-full object-cover object-center"
        />
      </div>
    </div>
    <div className="w-full md:w-1/2">
      <h2 className="text-4xl font-bold mb-6 text-gray-800">{title}</h2>
      {content.map((paragraph, index) => (
        <p key={index} className="text-gray-600 leading-relaxed mb-4 text-lg">
          {paragraph}
        </p>
      ))}
    </div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  isReversed: PropTypes.bool,
};

const AboutPage = () => {
  const sections = [
    {
      title: 'Notre histoire',
      content: [
      
      "Depuis 1995, nous avons à cœur d'embellir vos intérieurs avec des tapis qui racontent une histoire et apportent une touche de chaleur à chaque foyer. Ce projet est né de notre passion pour l'artisanat authentique et notre désir de créer des pièces qui allient tradition et modernité.", 
      "Chaque tapis que nous proposons est choisi avec soin pour offrir à nos clients un véritable cocon de confort et de style. Nous sommes ravis de partager notre amour des tapis avec vous, et de faire partie de votre quotidien."
      ],
      imageSrc: proposData[0],
      imageAlt: 'About Image 1',
    },
    {
      title: 'Notre mission et nos valeurs',
      content: [
        "Notre mission est de transformer chaque espace de vie en un lieu chaleureux grâce à des tapis qui allient beauté et qualité. Nous mettons un point d'honneur à offrir des créations uniques, en respectant les traditions artisanales et en privilégiant des matériaux qualité.",
        "Nos valeurs sont centrées sur l'authenticité, l'excellence, et un service client attentif, pour que chaque tapis que vous choisissez apporte confort et caractère à votre maison.",
      ],
      imageSrc: proposData[1],
      imageAlt: 'About Image 2',
      isReversed: true,
    },
    {
      title: 'Notre engagement',
      content: [
        'Notre engagement est de vous proposer des tapis de qualité, soigneusement sélectionnés pour répondre à vos attentes.',
        "Nous sommes une équipe passionnée qui s'investit pleinement pour vous offrir des tapis qui embellissent et enrichissent votre intérieur. Chaque tapis est choisi avec attention, afin de garantir à nos clients une expérience simple et satisfaisante, du choix jusqu'à la livraison.",
      ],
      imageSrc: proposData[2],
      imageAlt: 'About Image 3',
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 pt-28 bg-gray-50">
        <h1 className="text-5xl font-extrabold text-center text-gray-800 mb-12 tracking-tight">
          <span className="text-indigo-500 font-serif ">À propos de nous</span>
          
        </h1>      
        <div className="border-t-2 border-gray-300 mx-auto w-80 my-4 pt-10"></div>

        {sections.map((section, index) => (
        <React.Fragment key={section.title}>
          {index > 0 && <div className="my-20 border-t border-gray-200" />}
          <Section {...section} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default AboutPage;