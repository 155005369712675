import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 text-gray-600 shadow-[0_35px_70px_-15px_rgba(0,0,0,0.8)]">
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-wrap items-center justify-between">
          <div className="w-full md:w-auto">
            <h2 className="text-2xl font-semibold">TAPIS Toulouse</h2>
            <p className="text-gray-500 mt-2">© 2024 Tous droits réservés.</p>
          </div>
          <div className="w-full md:w-auto mt-4 md:mt-0">
            <ul className="flex flex-wrap items-center justify-center md:justify-end">
            <li>
                <a href="/" className="text-gray-500 hover:text-gray-800 mr-4 font-medium">Catalogue</a>
              </li>
              <li>
                <a href="/propos" className="text-gray-500 hover:text-gray-800 mr-4 font-medium">À propos</a>
              </li>
              {/* <li>
                <a href="/" className="text-gray-500 hover:text-gray-800 mr-4 font-medium">Contact</a>
              </li> */}

            </ul>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-400 pt-8">
          <p className="text-center text-gray-500">
            La Ville Rose, Toulouse, France
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;