import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';

const TopBanner = () => (
  <div className="bg-black text-[#FFD700] text-center py-2 text-sm font-medium">
    🚚 Livraison Gratuite sur Toulouse et Alentours | 💳 Paiement sur place à la livraison ! 
  </div>
);

const NavItem = ({ href, children }) => (
  <a
    href={href}
    className="text-lg font-medium text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out hover:underline"
  >
    {children}
  </a>
);

const MobileNavItem = ({ href, children, onClick }) => (
  <a
    href={href}
    onClick={onClick}
    className="block px-4 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 transition duration-150 ease-in-out"
  >
    {children}
  </a>
);

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const [showContactInfo, setShowContactInfo] = useState(false);
  const handleContactClick = () => setShowContactInfo(!showContactInfo);
  const handleWhatsappClick = () => {
    const message = `Bonjour, "`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className={`fixed top-0 left-0 right-0 z-50 bg-white transition-all duration-300 ${isScrolled ? 'shadow-md' : ''}`}>
      <TopBanner />
      <nav>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex-shrink-0 flex items-center">
            <span className="text-3xl font-serif text-gray-900 tracking-tight">TAPIS TOULOUSE</span>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-8">
            <NavItem href="/">Catalogue</NavItem>
            <NavItem href="/propos">À propos</NavItem>
            <NavItem href="#">
              <button
              onClick={handleContactClick}> Contact</button>
              </NavItem>
            <NavItem href="/blog">Blog</NavItem>
          </div>

          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gold transition duration-150 ease-in-out"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <MobileNavItem href="/" onClick={toggleMenu}>Catalogue</MobileNavItem>
            <MobileNavItem href="/propos" onClick={toggleMenu}>À propos</MobileNavItem>
            <MobileNavItem href="#" onClick={toggleMenu}><button
              onClick={handleContactClick}> Contact</button></MobileNavItem>
            <MobileNavItem href="/blog" onClick={toggleMenu}>Blog</MobileNavItem>
          </div>

        </div>
      )}
    </nav>

    
    {showContactInfo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4" onClick={handleContactClick}>
          <div className="relative bg-white py-6 sm:py-8 px-6 sm:px-10 rounded-lg w-full max-w-md mx-auto overflow-hidden shadow-xl" onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-2 right-2 sm:top-4 sm:right-4 text-2xl sm:text-3xl text-gray-700 hover:text-gray-900 transition-colors duration-300"
              onClick={handleContactClick}
            >
              ×
            </button>
            <div className="text-center">
              <FaPhoneAlt className="mx-auto mb-4 text-blue-500 text-3xl sm:text-4xl" />
              <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">Nous contacter</h2>
              <div className="flex items-center justify-center space-x-2 bg-gray-100 p-3 sm:p-4 rounded-lg mb-6">
                <FaPhoneAlt className="text-blue-500" />
                <span className="text-base sm:text-lg font-semibold text-gray-700">07 82 80 80 80</span>
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center space-y-3 sm:space-y-0 sm:space-x-4">
                <a
                  href="tel:0782808080"
                  className="w-full sm:w-auto bg-indigo-400 hover:bg-indigo-600 text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-lg transition-colors duration-300 text-sm sm:text-base"
                >
                  Appeler
                </a>
                <button
                  onClick={handleWhatsappClick}
                  className="w-full sm:w-auto flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-lg transition-colors duration-300 text-sm sm:text-base"
                >
                  <FaWhatsapp className="mr-2 text-xl sm:text-2xl" /> WhatsApp
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
};

export default Navbar;